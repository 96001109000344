@font-face {
  font-family: 'CostarBrown';
  src: url('./fonts/lineto-costar-brown-light.woff') format('woff');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'CostarBrown';
  src: url('./fonts/lineto-costar-brown-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'CostarBrown';
  src: url('./fonts/lineto-costar-brown-lightitalic.woff') format('woff');
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: 'icon-font-loopnet';
  src: url(./fonts/loopnet.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
}
[data-icon]:before,
[class^='ln-icon-']:before,
[class*=' ln-icon-']:before {
  display: inline-block;
  font-family: icon-font-loopnet;
  font-weight: normal;
  font-size: 1rem;
  font-variant: normal;
  text-transform: none;
  speak: none;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ln-icon-plus-filled:before {
  content: '\e64b';
}

.ln-icon-employees:before {
  content: '\e971';
}

.ln-icon-myprofile-filled:before {
  content: '\e629';
}

.ln-icon-myprofile-hollow:before {
  content: '\e62a';
}

.ln-icon-close-hollow:before {
  content: '\e608';
}

/* Fonts arent' working from here yet */
html,
body {
  font-family: 'CostarBrown' !important;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
}

/* HEADER  */

header {
  display: flex;
  flex-direction: row;
  color: #000;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 70px;
  min-height: 70px; // fixes safari issue
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.29);
  z-index: 5;

  h1 {
    margin-left: 10px;
    color: #000;
    font-weight: normal;
    span {
      margin-right: 7px;
      display: inline-block;
      color: #000;
      &::before {
        font-size: 28px !important;
      }
    }
  }

  img {
    margin-right: 10px;
    width: 165px;
  }

  img.full-width {
    margin-right: 0;
    width: 360px;
  }
}

.room-email-invite {
  color: #63666a;
  padding: 10px;
  display: inline-block;
  font-size: 18px;
  border: 0 none;
  background: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 5px;
  transition: all 0.13s ease-in-out;

  .icons {
    margin-right: 10px;
    .ln-icon-myprofile-filled::before {
      font-size: 24px;
      vertical-align: bottom;
    }

    .ln-icon-plus-filled::before {
      font-size: 12px;
    }
  }

  &:hover {
    color: #cf0000;
  }
}

.brand-realla {
  .room-email-invite {
    &:hover {
      color: #5abb57;
    }
  }
}

.add-friend-wrapper.room-email-field {
  position: absolute;
  right: 15px;
  top: 15px;
  margin-top: 0;
  margin-right: 0;

  form {
    display: inline-flex;
  }

  button.ln-icon-close-hollow {
    background-color: transparent;
    border: 0 none;
    padding: 13px 7px 7px;
    margin-right: 5px;
    color: #63666a;
    outline: none;
    vertical-align: bottom;
  }
}

.logo-divider {
  border-right: 2px solid #b5b5b5;
  width: 2px;
  height: 55px;
  margin: 0 10px 0 10px;
}

/*  JOIN ROOM  */

.container {
  //background-color: #fff;
  background: #fff url('./images/skyline.svg') repeat-x 0 30px;
  color: #000;
}

.header {
  display: flex;
  flex-direction: row;
  color: #000;
  align-items: center;
  justify-content: center;
}

.heading {
  background-color: #dadada;
  color: #000;
  width: 100%;
  padding: 10px 10px 10px 10px;
  font-weight: normal;
  margin: 0 auto;
}

.heading-wrapper {
  text-align: center;
  margin: 0 auto;
  max-width: 1000px;
}

.form-container {
  min-height: 500px;
  padding: 40px 15px;
  &.alt-page {
    min-height: auto;
  }
}

.form {
  color: #000;
  margin-top: 40px;
  border: 1px solid #c9c9c9;
  padding: 35px;
  margin: 0 auto;
  max-width: 1000px;
  background-color: #fff;
}

.welcome {
  margin-bottom: 40px;
  h2 {
    text-align: center;
    font-weight: 100;
    font-size: 18px;
  }
}

.please-accept {
  font-style: italic;
  text-align: center;
}

.field-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  &.alt-page {
    display: block;

    p {
      font-weight: 100 !important;
      font-size: 16px;
    }
  }

  &.alt-page h2 {
    color: #000;
    font-size: 21px;
    font-weight: normal !important;
    text-align: center;
  }
}

.name-section {
  margin-right: 60px;
  width: 450px;

  input {
    margin-top: 10px;
  }
}

.access-code {
  margin-bottom: 30px;

  input {
    margin-top: 10px;
  }
}

.room-section {
  width: 450px;
}

.text-field {
  color: #000 !important;
  border: 1px solid #b1b3b0;
  padding: 7px 10px;
  height: 43px;
  width: 100%;
  font-size: 16px;
}

.loading-spinner {
  margin-left: 1em;
}

.display-name {
  margin-left: 2.2em;
  min-width: 200px;
  font-weight: 600;
  color: #000;
}

.label {
  color: #000;
  font-size: 22px;
  font-weight: normal !important;
}

.submit-button {
  background-color: #cf0000;
  color: #fff;
  border: 0 none;
  padding: 10px;
  font-size: 17px;
  margin-top: 25px;
  width: 100%;
  cursor: pointer;
}

.brand-realla {
  .submit-button {
    background-color: #5abb57;
  }
}
.browser-message {
  text-align: center;
  font-size: 18px;
  color: #cf0000;
}
.brand-realla {
  .browser-message {
    color: #5abb57;
  }
}
.room-joined {
  padding-left: 10px;
  height: 35px;
}

.room-name {
  font-weight: normal;
}

.spinner-container {
  margin: 70px auto 0;
  text-align: center;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 300px;
  margin: 0 auto;
  &.mobile {
    display: none;
  }
  &.desktop {
    display: flex;
  }
}

.terms {
  text-align: right;
  font-weight: 100;
}

.terms a {
  color: #0888ba;
}

input,
button {
  font-family: 'CostarBrown' !important;
  font-weight: 100;
}

.add-friend-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 15px;

  button {
    border: 0 none;
    background-color: #cf0000;
    padding: 5px 7px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
  }
}

.brand-realla {
  .add-friend-wrapper {
    button {
      background-color: #5abb57;
    }
  }
}

.add-friends {
  border: 0 none;
  background-color: transparent;
  padding: 5px 0 5px 6px;
  color: #000;
  margin: 5px 0 5px 0;
  display: block;
  outline: none;
  float: right;
  cursor: pointer;

  .ln-icon-employees {
    margin-right: 3px;
  }

  .ln-icon-employees::before {
    font-size: 21px;
  }
}

button:disabled {
  transition: color 0.3s linear;
  color: #fff;
  background-color: rgba(207, 0, 0, 0.6);
}

.brand-realla {
  button:disabled {
    background-color: rgba(90, 187, 87, 0.6);
  }
}

.MuiFab-primary,
.MuiFab-primary:hover {
  background-color: #cf0000 !important;
}

.brand-realla {
  .MuiFab-primary:hover {
    background-color: #5abb57 !important;
  }
}

.MuiCircularProgress-colorPrimary {
  color: #cf0000 !important;
}

.brand-realla {
  .MuiCircularProgress-colorPrimary {
    color: #5abb57 !important;
  }
}

/* TODO REFACTOR MEDIA QUERIES AND BREAK OUT STYLESHEETS */

@media (max-width: 575.98px) {
  .field-wrapper {
    flex-direction: column !important;
  }

  .name-section,
  .room-section {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  .button-wrapper {
    width: 100% !important;
  }
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .field-wrapper {
    flex-direction: column !important;
  }

  .welcome {
    h2 {
      font-size: 14px;
    }
  }

  .name-section,
  .room-section {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  .button-wrapper {
    width: 100% !important;
    &.mobile {
      display: flex;
    }
    &.desktop {
      display: none;
    }
  }
  .label {
    font-size: 18px !important;
  }

  .room-email-invite {
    display: none;
  }

  .add-friend-wrapper.room-email-field {
    display: none;
  }

  .invite-text {
    display: none;
  }
}

@media (max-width: 769px) and (orientation: portrait) {
  .field-wrapper {
    flex-direction: column !important;
  }

  .name-section,
  .room-section {
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  .button-wrapper {
    width: 100% !important;
  }
}

/* Medium devices (tablets, less than 992px) */

/* Large devices (desktops, less than 1200px) */

/* ROOM STYLES - todo move this to another stylesheet  */

.room-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 70px);

  header {
    height: 70px;
    min-height: 70px; //fixes safari issue
    flex: 0 1 auto;
  }
}

img.broker-logo {
  width: 60px;
}

.main-participant-container {
  flex: 1;
  flex-basis: auto;
  background-color: #fafafa;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.29);
  position: relative;
}

.participant-strip-container {
  // display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 260px;
  flex: 0 1 auto;
  background-color: #fafafa;
  flex-basis: auto;

  &:hover {
    overflow-y: auto;
  }

  svg {
    stroke: none !important;
  }
}

.participant-strip-container > div {
  flex-grow: 1;
  max-height: 146px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.29);
  z-index: 1;
}

main {
  text-align: center;
  background-color: #fff;
}

main > video {
  width: auto !important;
  position: relative !important;
}

.video-preview-window {
  position: absolute !important;
  width: 260px;
  left: 50% !important;
  transform: translateX(-50%);
  height: 146px;
  margin: 0 auto;

  video {
    left: 0;
  }
}

.preview-controls {
  position: absolute;
  left: 50%;
  top: 300px;
  transform: translateX(-50%);
}

.no-video,
.no-video-room,
.no-video-room-main {
  width: 260px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #b5b5b5;
  height: 146px;
  margin: 0 auto;

  span {
    color: #fff;
    &::before {
      font-size: 100px;
    }
  }
}
.no-video-room,
.no-video-room-main {
  top: 0;
  margin: 0 auto;
}

.no-video-room-main {
  width: 100%;
  height: 100%;
  position: relative;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &::before {
      font-size: 25em;
    }
  }
}

@media (max-width: 769px) and (orientation: portrait) {
  .no-video {
    margin: 0 auto;
    transform: translate(-50%, -40px);
  }
}

.MuiButton-textPrimary {
  color: #cf0000 !important;
}

.brand-realla {
  .MuiButton-textPrimary {
    color: #5abb57 !important;
  }
}

.MuiPaper-rounded {
  // we do not actually want to do this - fix in v2
  border-radius: 0 !important;
}

.indicators {
  z-index: 1;
}

.audio-indicator {
  background: rgba(0, 0, 0, 0.29);
  padding: 1px;
}
